<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">机构收款统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start;">
          <div class="searchbox">
            <div title="班级搜索" style="display: flex; align-items: center" class="searchboxItem ci-full">
              <span class="itemLabel">班级搜索:</span>
              <el-select v-model="projectId" placeholder="请选择" remote size="small" clearable :remote-method="superProjectSelect" @visible-change="clearProjectSearchModel">
                <template>
                  <div class="select-header">
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      ">班级编号</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      ">班级名称</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="seaProjectCode" v-on:input="superProjectSelect" type="text" size="small" placeholder="班级编号" clearable style="width: 150px" />
                    <el-input v-model="seaProjectName" v-on:input="superProjectSelect" type="text" size="small" placeholder="请输入班级名称" clearable style="margin-left: 50px; width: 250px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName" :value="item.projectId" style="display: flex;justify-content:space-between;align-items: center;overflow:hidden;word-wrap:break-word;word-break:break-all;white-space: pre-wrap;height:auto">
                  <span style="width: 180px; font-size: 13px;">{{
                    item.projectCode
                  }}</span>
                  <span style="width: 250px;font-size: 13px;">{{ item.projectName }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-input v-model="compName" type="text" size="small" clearable placeholder="请输入机构名称" />
            </div>
            <div title="开班日期" class="searchboxItem ci-full">
              <span class="itemLabel">开班日期:</span>
              <el-date-picker v-model="dateList" size="small" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value="yyyy-MM-dd" value-format="yyyy-MM-dd" />
            </div>
            <div class="df" style="margin-left:10px">
              <el-button type="primary" class="bgc-bv" round @click="getData(1,true)">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="exportData">导出</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="statistics" v-if="tableData.length">
              <span>平台服务费总计:{{ platformSettlementAmount }} <i v-if="platformSettlementAmount===null" class="el-icon-loading"></i></span>
              <span>到账总计:{{ totalAmountReceived }} <i v-if="totalAmountReceived===null" class="el-icon-loading"></i></span>
            </div>
            <el-table class="className" ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" min-width="180" />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="220" />
              <el-table-column label="培训有效期" align="left" show-overflow-tooltip min-width="220" >
                 <template slot-scope="{row}">
                  {{row.startDate}} ~ {{row.endDate}}
                </template>
              </el-table-column>
              <el-table-column label="开班机构" align="left" show-overflow-tooltip prop="compName" min-width="180" />
              <el-table-column label="结算人数" align="left" show-overflow-tooltip prop="settleNum" min-width="80" />
              <el-table-column label="平台服务费" align="left" show-overflow-tooltip prop="platformSettlementAmount" min-width="80" />
              <el-table-column label="到账金额" align="left" show-overflow-tooltip prop="totalAmountReceived" min-width="80" />
              <el-table-column label="有无确认" align="left" show-overflow-tooltip prop="affirmState" min-width="120">
                <template slot-scope="{row}">
                  {{affirmStateStr(row.affirmState)}}
                </template>
              </el-table-column>
              <el-table-column label="课程方一" align="left" show-overflow-tooltip min-width="180">
                <template slot-scope="{row}">
                  {{row.projectCourseProviderBOS[0]?row.projectCourseProviderBOS[0].compName:''}}
                </template>
              </el-table-column>
              <el-table-column label="使用课时数" align="left" show-overflow-tooltip min-width="80">
                <template slot-scope="{row}">
                  {{row.projectCourseProviderBOS[0]?row.projectCourseProviderBOS[0].totalLessonNum:''}}
                </template>
              </el-table-column>
              <el-table-column label="课程方二" align="left" show-overflow-tooltip min-width="180">
                <template slot-scope="{row}">
                  {{row.projectCourseProviderBOS[1]?row.projectCourseProviderBOS[1].compName:''}}
                </template>
              </el-table-column>
              <el-table-column label="使用课时数" align="left" show-overflow-tooltip min-width="80">
                <template slot-scope="{row}">
                  {{row.projectCourseProviderBOS[1]?row.projectCourseProviderBOS[1].totalLessonNum:''}}
                </template>
              </el-table-column>
              <el-table-column label="课程方三" align="left" show-overflow-tooltip min-width="180">
                <template slot-scope="{row}">
                  {{row.projectCourseProviderBOS[2]?row.projectCourseProviderBOS[2].compName:''}}
                </template>
              </el-table-column>
              <el-table-column label="使用课时数" align="left" show-overflow-tooltip min-width="80">
                <template slot-scope="{row}">
                  {{row.projectCourseProviderBOS[2]?row.projectCourseProviderBOS[2].totalLessonNum:''}}
                </template>
              </el-table-column>
              <el-table-column label="课程方四" align="left" show-overflow-tooltip min-width="180">
                <template slot-scope="{row}">
                  {{row.projectCourseProviderBOS[3]?row.projectCourseProviderBOS[3].compName:''}}
                </template>
              </el-table-column>
              <el-table-column label="使用课时数" align="left" show-overflow-tooltip min-width="80">
                <template slot-scope="{row}">
                  {{row.projectCourseProviderBOS[3]?row.projectCourseProviderBOS[3].totalLessonNum:''}}
                </template>
              </el-table-column>
              <el-table-column label="课程方五" align="left" show-overflow-tooltip min-width="180">
                <template slot-scope="{row}">
                  {{row.projectCourseProviderBOS[4]?row.projectCourseProviderBOS[4].compName:''}}
                </template>
              </el-table-column>
              <el-table-column label="使用课时数" align="left" show-overflow-tooltip min-width="80">
                <template slot-scope="{row}">
                  {{row.projectCourseProviderBOS[4]?row.projectCourseProviderBOS[4].totalLessonNum:''}}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" fixed="right" width="120">
                <template slot-scope="{row}">
                  <el-button type="text" :disabled="row.affirmState == 0" size="mini" @click="getSettlementDetails(row.projectId)">查看结算详情</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog :visible.sync="settlementDialogData.state" v-if="settlementDialogData.state" top="5%" width="80%" title="结算单详情" :center="true">
        <el-table class="className" :data="settlementDialogData.list" height="550" :header-cell-style="tableHeader" stripe>
          <el-table-column label="序号" align="center" type="index" />
          <el-table-column label="结算单编号" align="left" prop="billNo" min-width="180" />
          <el-table-column label="收费标准" align="left" class-name="pre-wrap" prop="chargesSettlement" min-width="180" />
          <el-table-column label="协议约定结算方式" align="left" prop="settlementMethod" min-width="180">
             <template slot-scope="{row}">
              {{$setDictionary('CONTRACT_SETTLEMENT_METHOD',row.settlementMethod)}}
            </template>
          </el-table-column>
          <el-table-column label="结算人数" align="left" prop="settleNum" min-width="100" />
          <el-table-column label="国家补贴标准" align="left" prop="countrySubsidyStandard" min-width="110" />
          <el-table-column label="结算标准" align="left" prop="settleStandard" min-width="100" />
          <el-table-column label="课时数" align="left" prop="totalLessonNum" min-width="100" />
          <el-table-column label="结算费用" align="left" prop="settleFee" min-width="100" />
          <el-table-column label="平台服务费" align="left" prop="platformSettlementAmount" min-width="100" />
          <el-table-column label="到账金额" align="left" prop="totalAmountReceived" min-width="100" />
          <el-table-column label="有无确认" align="left" prop="affirmState" min-width="100">
            <template slot-scope="{row}">
              {{row.affirmState?'已确认':'未确认'}}
            </template>
          </el-table-column>
        </el-table>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import { mapGetters } from "vuex";
import List from "@/mixins/List";
export default {
  name: "agenciesGatheringStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  doNotInit: true,
  data () {
    return {
      compName: "",
      projectId: "",
      dateList: [],
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
      settlementDialogData: {
        state: false,
        list: []
      },
      platformSettlementAmount: null,
      totalAmountReceived: null,
      queryStatus: true,//查询状态
    };
  },
  created () {
    this.superProjectSelect();
    this.getData(1, true)
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted () { },
  methods: {
    // 映射
    affirmStateStr (affirmState) {
      switch (affirmState) {
        case 0:
          return '未结算'
        case 1:
          return '未确认'
        case 2:
          return '未完全确认'
        case 3:
          return '已确认'
        default:
          return '未知'
      }
    },
    // 获取结算单详情
    getSettlementDetails (projectId) {
      this.$post('/biz/new/bill/comp/payment/statistics/detail', { projectId })
        .then(res => {
          this.settlementDialogData.list = res.data ?? []
          this.settlementDialogData.state = true
        })
    },
    getData (pageNum = 1, flag = false) {
      if (!this.queryStatus && flag) {
        this.$message.warning('正在查询统计数据,请完成后再试！')
        return
      }
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      if (this.dateList?.length === 2) {
        params.startDate = this.dateList[0];
        params.endDate = this.dateList[1];
      }
      if (flag) {
        const data = {
          ...params
        }
        delete data.pageNum
        delete data.pageSize
        this.getcount(data);
      }
      this.doFetch({
        url: "/biz/new/bill/comp/payment/statistics/pageList",
        params,
        pageNum,
      });
    },
    // 统计数据
    getcount (params) {
      this.platformSettlementAmount = null,//完成人数
        this.totalAmountReceived = null,//结业人数
        this.queryStatus = false
      this.$post('/biz/new/bill/comp/payment/statistics', params)
        .then((res) => {
          this.platformSettlementAmount = res?.data?.platformSettlementAmount ?? 0;
          this.totalAmountReceived = res?.data?.totalAmountReceived ?? 0;
          this.queryStatus = true
        })
        .catch((err) => {
          this.queryStatus = true
          return;
        });
    },
    // 导出
    exportData () {
      const params = {};
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      if (this.dateList?.length === 2) {
        params.startDate = this.dateList[0];
        params.endDate = this.dateList[1];
      }
      this.$post("/biz/new/bill/comp/payment/statistics/export", params).then((res) => {
        const arr = res.data;
        for (let item of arr) {
          if (!this.downloadItems.includes(item.taskId)) {
            console.log(item.taskId);
            this.$store.dispatch("pushDownloadItems", item.taskId);
          } else {
            this.$message.warning(
              "[" + item.fileName + "]已经申请下载,请耐心等待"
            );
          }
        }
      });
    },
    superProjectSelect (e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    clearProjectSearchModel (e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superProjectSelect();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.statistics {
  margin-bottom: 10px;
  > span + span {
    margin-left: 10px;
  }
}
.className :empty:not(th):not(tbody)::before{
	content:'--';
	color:#606266;
}
/deep/ .pre-wrap .cell{
  white-space: pre-wrap;
}
</style>
